<template>
  <div>
    <!--Overlay Effect-->
    <div v-if="dialog" class="fixed inset-0 bg-black bg-opacity-70 overflow-y-auto h-full w-full z-50" id="modal-contact">
      <div class="w-full h-full absolute" @click="cancel()"></div>
      <!--modal content-->
      <div class="relative top-1/2 transform -translate-y-1/2 mx-auto max-w-screen-lg rounded-2xl">
        <div class="py-4 px-9 flex items-center justify-between bg-primary rounded-t-2xl">
          <h2 class="text-2xl font-bold text-white">Contact Details</h2>
          <button class="flex items-center p-1 justify-center text-white text-xl" @click="cancel()">
            <font-awesome-icon icon="fa-solid fa-x" />
          </button>
        </div>
        <div class="py-8 px-9 bg-white rounded-b-2xl">
          <div class="flex items-center mb-0.5">
            <p class="text-xs font-semibold">Title<span>*</span></p>
            <div class="ml-9 flex items-center">
              <input type="radio" name="title" class="w-4 h-4">
              <label class="text-base font-semibold ml-4" for="">Mr.</label>
            </div>
            <div class="ml-10 flex items-center">
              <input type="radio" name="title" class="w-4 h-4">
              <label class="text-base font-semibold ml-4" for="">Mrs.</label>
            </div>
          </div>
          <div class="flex -mx-4">
            <div class="w-1/2 px-4">
              <p class="text-xs font-semibold mb-1.5">Nationality<span>*</span></p>
              <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg">
            </div>
            <div class="w-1/2 px-4">
              <p class="text-xs font-semibold mb-1.5">Date of Birth<span>*</span></p>
              <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg">
            </div>
          </div>
          <div class="flex -mx-4 mt-4">
            <div class="w-1/2 px-4">
              <p class="text-xs font-semibold mb-1.5">Full Name<span>*</span></p>
              <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg">
            </div>
            <div class="w-1/2 px-4">
              <p class="text-xs font-semibold mb-1.5">Identity Number NIK/SIM/Passport/KITAS<span>*</span></p>
              <div class="flex -mx-2">
                <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-1/3 rounded-lg mx-2">
                <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-2/3 rounded-lg mx-2">
              </div>
            </div>
          </div>
          <div class="flex -mx-4 mt-4">
            <div class="w-1/2 px-4">
              <p class="text-xs font-semibold mb-1.5">Phone Number<span>*</span></p>
              <div class="flex items-start -mx-2">
                <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-1/4 rounded-lg mx-2">
                <div class="w-3/4 px-2">
                  <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg">
                  <p class="text-xs mt-2 text-rounded font-medium">e.g. +62812345678, for country code (+62)</p>
                  <p class="text-xs mt-0.5 text-rounded font-medium">and mobile no. 0812345678</p>
                </div>
              </div>
            </div>
            <div class="w-1/2 px-4">
              <p class="text-xs font-semibold mb-1.5">Email<span>*</span></p>
              <input type="text" class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg">
            </div>
          </div>
          <div class="flex justify-end mt-5">
            <button class="py-2 px-16 bg-primary text-white text-xl font-bold rounded-lg">Save</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Content End -->
  </div>
</template>

<script>
export default {
  name: 'ModalContactInsurance',
  components: {
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    cancel() {
      this.dialog = false
    },
  }
}
</script>